import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { useLocation, Link } from 'react-router-dom';
import ImageModal from '../components/ImageModal'; 
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import '../style/products.css';
import { FaTrash } from 'react-icons/fa';

const Products = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopId = queryParams.get('shop');

  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const designsPerPage = 7;

  // Modal state for confirmation
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [designToDelete, setDesignToDelete] = useState(null);

  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not logged in");
  }

  useEffect(() => {
    const fetchDesigns = async () => {
      try {
        const designsCollection = collection(db, 'users', user.uid, 'designs');
        const q = query(designsCollection, orderBy('timestamp', 'desc'));
        const designSnapshot = await getDocs(q);
        const designList = designSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDesigns(designList);
      } catch (error) {
        console.error("Error fetching designs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDesigns();
  }, [shopId]);

  // Calculate pagination
  const totalPages = Math.ceil(designs.length / designsPerPage);
  const indexOfLastDesign = currentPage * designsPerPage;
  const indexOfFirstDesign = indexOfLastDesign - designsPerPage;
  const currentDesigns = designs.slice(indexOfFirstDesign, indexOfLastDesign);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleDeleteClick = (designId) => {
    setDesignToDelete(designId);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      const designDoc = doc(db, 'users', user.uid, 'designs', designToDelete);
      await deleteDoc(designDoc);
      setDesigns(designs.filter(design => design.id !== designToDelete));
    } catch (error) {
      console.error("Error deleting design:", error);
    } finally {
      setShowDeleteModal(false);
      setDesignToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setDesignToDelete(null);
  };

  if (loading) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="products-page">
      <Header />
      <div className="products-container">
        <Sidebar />
        <div className="main-content">
          <Link to={'/select-tshirt'} className="create-button">Create Product</Link>

          {designs.length === 0 ? (
            <p>No designs uploaded yet for this shop.</p>
          ) : (
            <>
             <table className="design-table">
  <thead>
    <tr>
      <th>Design Name</th>
      <th>Description</th>
      <th>Variants</th>
      <th>Print Method</th>
      <th>Print Cost</th>
      <th>Selling Price</th>
      <th>Total Price</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {currentDesigns.map(design => {
      // For designs with variants, create a row for each variant
      if (design.colorVariants && design.colorVariants.length > 0) {
        return design.colorVariants.map((variant, index) => (
          <tr key={`${design.id}-${variant.color}`}>
            {/* Only add these cells on the first row of the design */}
            {index === 0 && (
              <>
                <td rowSpan={design.colorVariants.length}>
                  {design.designName}
                </td>
                <td rowSpan={design.colorVariants.length}>
                  {design.description}
                </td>
              </>
            )}
            <td>
              <p><strong>{variant.productName}</strong></p>
              <p>{variant.color}</p>
              <img
                src={variant.images?.front}
                alt="Front Design"
                style={{ width: '50px', height: 'auto' }}
                onClick={() => handleImageClick(variant.images?.front)}
              />
              <img
                src={variant.images?.back}
                alt="Back Design"
                style={{ width: '50px', height: 'auto' }}
                onClick={() => handleImageClick(variant.images?.back)}
              />
            </td>

            <td>{design.printMethod}</td>
            <td>
              <p>
                Front Print: {variant.costs?.front} <br />
                Back Print: {variant.costs?.back}
              </p>
            </td>
            {index === 0 && (
              <td rowSpan={design.colorVariants.length}>
                {design.sellingPrice}
              </td>
            )}
            <td>
              <p>{variant.totalPrice}</p>
            </td>
            {index === 0 && (
              <td rowSpan={design.colorVariants.length}>
                <button onClick={() => handleDeleteClick(design.id)} className="delete-button">
                  <FaTrash />
                </button>
              </td>
            )}
          </tr>
        ));
      } else {
        // For designs without variants, create a single row
        return (
          <tr key={design.id}>
            <td>{design.designName}</td>
            <td>{design.description}</td>
            <td>
              <p>{design.productName}</p>
              <img
                src={design.images?.front}
                alt="Front Design"
                style={{ width: '50px', height: 'auto' }}
                onClick={() => handleImageClick(design.images?.front)}
              />
              <img
                src={design.images?.back}
                alt="Back Design"
                style={{ width: '50px', height: 'auto' }}
                onClick={() => handleImageClick(design.images?.back)}
              />
            </td>

            <td>{design.printMethod}</td>
            <td>
              <p>Front Print: {design.costs?.front} <br /> Back Print: {design.costs?.back}</p>
            </td>
            <td>{design.sellingPrice}</td>
            <td>{design.totalPrice}</td>
            <td>
              <button onClick={() => handleDeleteClick(design.id)} className="delete-button">
                <FaTrash />
              </button>
            </td>
          </tr>
        );
      }
    })}
  </tbody>
</table>
              {/* Pagination Controls */}
              <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>

              <ImageModal imageSrc={selectedImage} onClose={handleCloseModal} />
            </>
          )}
        </div>
      </div>

      {/* Confirmation Modal for Deleting */}
      {showDeleteModal && (
        <div className="modals">
          <div className="modal-contents">
            <h3>Are you sure you want to delete this design?</h3>
            <button onClick={handleDelete} className="yes-button">Yes</button>
            <button onClick={handleCancelDelete} className="no-button">No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
