// import React, { useEffect, useState } from 'react';
// import { db } from '../firebase';
// import { collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
// import { useLocation, Link } from 'react-router-dom';
// import ImageModal from '../components/ImageModal'; 
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';
// import '../style/products.css';
// import { FaTrash } from 'react-icons/fa';

// const Products = () => {
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const shopId = queryParams.get('shop');

//   const [designs, setDesigns] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [showDeleteModal, setShowDeleteModal] = useState(false); // NEW
//   const [designToDelete, setDesignToDelete] = useState(null); // NEW
  
//   // Pagination State
//   const [currentPage, setCurrentPage] = useState(1); // NEW
//   const designsPerPage = 7; // NEW

//   useEffect(() => {
//     const fetchDesigns = async () => {
//       try {
//         if (!shopId) {
//           throw new Error("Shop ID is missing");
//         }

//         const designsCollection = collection(db, 'shops', shopId, 'designs');
//         const q = query(designsCollection, orderBy('timestamp', 'desc'));
//         const designSnapshot = await getDocs(q);
//         const designList = designSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setDesigns(designList);
//       } catch (error) {
//         console.error("Error fetching designs:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDesigns();
//   }, [shopId]);

//   // Calculate pagination
//   const totalPages = Math.ceil(designs.length / designsPerPage); // NEW
//   const indexOfLastDesign = currentPage * designsPerPage; // NEW
//   const indexOfFirstDesign = indexOfLastDesign - designsPerPage; // NEW
//   const currentDesigns = designs.slice(indexOfFirstDesign, indexOfLastDesign); // NEW

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber); // NEW
//   };

//   const handleImageClick = (imageSrc) => {
//     setSelectedImage(imageSrc); // Set the clicked image as the selected one
// };

// const handleCloseModal = () => {
//     setSelectedImage(null); // Close the modal by clearing the selected image
// };


// const handleDeleteClick = (designId) => {
//   setDesignToDelete(designId); // Set the design ID to be deleted
//   setShowDeleteModal(true); // Show the confirmation modal
// };


// const handleDelete = async () => {
//   try {
//     const designDoc = doc(db, 'shops', shopId, 'designs', designToDelete);
//     await deleteDoc(designDoc);
//     setDesigns(designs.filter(design => design.id !== designToDelete)); // Remove deleted design from the UI
//   } catch (error) {
//     console.error("Error deleting design:", error);
//   } finally {
//     setShowDeleteModal(false); // Close the modal after deletion
//     setDesignToDelete(null); // Clear the design ID
//   }
// };


// const handleCancelDelete = () => {
//   setShowDeleteModal(false); // Close the modal if the user cancels
//   setDesignToDelete(null); // Clear the design ID
// };

//   if (loading) {
//     return <div className="loading-message">Loading...</div>;
//   }

//   return (
//     <div className="products-page">
//       <Header />
//       <div className="products-container">
//         <Sidebar />
//         <div className="main-content">
//           <Link to={`/selecttshirt?shop=${shopId}`} className="create-button">Create Product</Link>
//           {designs.length === 0 ? (
//             <p>No designs uploaded yet for this shop.</p>
//           ) : (
//             <>
//               <table className="design-table">
//                 <thead>
//                   <tr>
//                     <th>Design Name</th>
//                     <th>Description</th>
//                     <th>Product Name</th>
//                     <th>Front Mockup</th>
//                     <th>Back Mockup</th>
//                     <th>Print Cost</th>
//                     <th>Selling Price</th>
//                     <th>Total Price</th>
//                     <th>Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {currentDesigns.map(design => (
//                     <tr key={design.id}>
//                       <td>{design.designName}</td>
//                       <td>{design.description}</td>
//                       <td>{design.productName}</td>
                       
//                       <td><img src={design.images.front} alt='Front Design' style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(design.images.front)}  /></td>
//                       <td><img src={design.images.back} alt="Back Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(design.images.back)}  /></td>
//                       <td>Front Print: {design.costs.front} <br></br> Back Print: {design.costs.back}</td>
//                       <td>{design.sellingPrice}</td>
//                       <td>{design.totalPrice}</td>
//                       <td>
//                         <button onClick={() => handleDelete(design.id)} className="delete-button"><FaTrash/></button> {/* Delete Button */}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//               {/* Pagination Controls */}
//               <div className="pagination"> {/* NEW */}
//                 {Array.from({ length: totalPages }, (_, index) => (
//                   <button
//                     key={index + 1}
//                     onClick={() => handlePageChange(index + 1)}
//                     className={currentPage === index + 1 ? 'active' : ''} // NEW
//                   >
//                     {index + 1}
//                   </button>
//                 ))}
//               </div> 
//               <ImageModal imageSrc={selectedImage} onClose={handleCloseModal} />
//             </>
//           )}
//         </div>
//       </div>

//       {showDeleteModal && (
//         <div className="delete-modal">
//           <div className="modal-content">
//             <h3>Are you sure you want to delete this design?</h3>
//             <button onClick={handleDelete} className="yes-button">Yes</button>
//             <button onClick={handleCancelDelete} className="no-button">No</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Products;


import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { useLocation, Link } from 'react-router-dom';
import ImageModal from '../components/ImageModal'; 
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import '../style/products.css';
import { FaTrash } from 'react-icons/fa';

const Products = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopId = queryParams.get('shop');

  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  
  // Pagination State
  const [currentPage, setCurrentPage] = useState(1); // NEW
  const designsPerPage = 7; // NEW
  
  // Modal state for confirmation
  const [showDeleteModal, setShowDeleteModal] = useState(false); // NEW
  const [designToDelete, setDesignToDelete] = useState(null); // NEW



  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not logged in");
  }


  useEffect(() => {
    const fetchDesigns = async () => {
      try {
        // if (!shopId) {
        //   throw new Error("Shop ID is missing");
        // }

        const designsCollection = collection(db, 'users', user.uid, 'designs');
        const q = query(designsCollection, orderBy('timestamp', 'desc'));
        const designSnapshot = await getDocs(q);
        const designList = designSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDesigns(designList);
      } catch (error) {
        console.error("Error fetching designs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDesigns();
  }, [shopId]);

  // Calculate pagination
  const totalPages = Math.ceil(designs.length / designsPerPage); // NEW
  const indexOfLastDesign = currentPage * designsPerPage; // NEW
  const indexOfFirstDesign = indexOfLastDesign - designsPerPage; // NEW
  const currentDesigns = designs.slice(indexOfFirstDesign, indexOfLastDesign); // NEW

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // NEW
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc); // Set the clicked image as the selected one
  };

  const handleCloseModal = () => {
    setSelectedImage(null); // Close the modal by clearing the selected image
  };

  const handleDeleteClick = (designId) => {
    setDesignToDelete(designId); // Set the design ID to be deleted
    setShowDeleteModal(true); // Show the confirmation modal
  };

  const handleDelete = async () => {
    try {
      const designDoc = doc(db, 'users', user.uid, 'designs', designToDelete);
      await deleteDoc(designDoc);
      setDesigns(designs.filter(design => design.id !== designToDelete)); // Remove deleted design from the UI
    } catch (error) {
      console.error("Error deleting design:", error);
    } finally {
      setShowDeleteModal(false); // Close the modal after deletion
      setDesignToDelete(null); // Clear the design ID
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false); // Close the modal if the user cancels
    setDesignToDelete(null); // Clear the design ID
  };

  if (loading) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="products-page">
      <Header />
      <div className="products-container">
        <Sidebar />
        <div className="main-content">
          <Link to={'/selecttshirt'} className="create-button">Create Product</Link>
          {designs.length === 0 ? (
            <p>No designs uploaded yet for this shop.</p>
          ) : (
            <>
              <table className="design-table">
                <thead>
                  <tr>
                    <th>Design Name</th>
                    <th>Description</th>
                    <th>Product Name</th>
                    <th>Print Method</th>
                    <th>Front Mockup</th>
                    <th>Back Mockup</th>
                    <th>Print Cost</th>
                    <th>Selling Price</th>
                    <th>Total Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentDesigns.map(design => (
                    <tr key={design.id}>
                      <td>{design.designName}</td>
                      <td>{design.description}</td>
                      <td>{design.productName}</td>
                      <td>{design.printMethod}</td>
                      <td><img src={design.images.front} alt='Front Design' style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(design.images.front)}  /></td>
                      <td><img src={design.images.back} alt="Back Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(design.images.back)}  /></td>
                      <td>Front Print: {design.costs.front} <br></br> Back Print: {design.costs.back}</td>
                      <td>{design.sellingPrice}</td>
                      <td>{design.totalPrice}</td>
                      <td>
                        <button onClick={() => handleDeleteClick(design.id)} className="delete-button"><FaTrash/></button> {/* Delete Button */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination Controls */}
              <div className="pagination"> {/* NEW */}
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''} // NEW
                  >
                    {index + 1}
                  </button>
                ))}
              </div> 
              <ImageModal imageSrc={selectedImage} onClose={handleCloseModal} />
            </>
          )}
        </div>
      </div>

      {/* Confirmation Modal for Deleting */}
      {showDeleteModal && (
        <div className="modals">
          <div className="modal-contents">
            <h3>Are you sure you want to delete this design?</h3>
            <button onClick={handleDelete} className="yes-button">Yes</button>
            <button onClick={handleCancelDelete} className="no-button">No</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
