// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase';
// import { collection, getDocs } from 'firebase/firestore';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const NDRreports = () => {
//     const [rtoOrders, setRtoOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const location = useLocation();
//     const [searchTerm, setSearchTerm] = useState('');
//     const [filteredOrders, setFilteredOrders] = useState([]);
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop');

//     const user = auth.currentUser;
//     if (!user) {
//         throw new Error("User not logged in");
//     }

//     const fetchRtoOrders = async () => {
//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const ordersSnapshot = await getDocs(ordersCollection);
//             const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
//             const filteredOrders = [];
//             for (const order of ordersList) {
//                 const trackingInfo = await fetchTrackingData(order.id);
//                 if (trackingInfo.current_status === "Undelivered" || 
//                     trackingInfo.current_status.includes("Undelivered") ||
//                     trackingInfo.current_status.includes("undelivered") ||
//                     trackingInfo.current_status.includes("attempt") ||
//                     trackingInfo.current_status.includes("Attempt")) {
//                     filteredOrders.push({ ...order, tracking_status: trackingInfo.current_status });
//                 }
//             }
            
//             setRtoOrders(filteredOrders);
//         } catch (error) {
//             console.error("Error fetching RTO orders:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const fetchTrackingData = async (orderId) => {
//         try {
//             const response = await axios.get(`https://ufb-1.onrender.com/api/track-order?order_id=${orderId}`);
//             if (response.data && response.data.current_status) {
//                 return {
//                     tracking_url: response.data.tracking_url,
//                     current_status: response.data.current_status
//                 };
//             }
//         } catch (error) {
//             console.error("Error fetching tracking data for order", orderId, error);
//         }
//         return { tracking_url: null, current_status: "Status not available" };
//     };

//     useEffect(() => {
//         fetchRtoOrders();
//     }, [shopId]);


//         useEffect(() => {
//             const filtered = rtoOrders.filter(order => 
//                 order.id.includes(searchTerm) || 
//                 (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
//             );
//             setFilteredOrders(filtered);
//         }, [searchTerm, rtoOrders]);

//     if (loading) {
//         return <div>Loading NDRs</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>NDR Orders</h1>
//                     <input 
//                         type="text" 
//                         placeholder="Search by Order ID or Store Order ID" 
//                         value={searchTerm} 
//                         onChange={(e) => setSearchTerm(e.target.value)} 
//                         className="search-bar"
//                     />
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th>Unitee Order ID</th>
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 <th>Current Status</th>
//                                 {/* <th>Actions</th> */}
//                             </tr>
//                         </thead>
//                         <tbody>
//                         {filteredOrders
//                                 .map(order => (
//                                 <tr key={order.id}>
//                                     <td>{order.Storeorder_id || 'NA'}</td>
//                                     <td>{order.id || 'NA'}</td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.sku}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.units}</p>
//                                         ))}
//                                     </td>
//                                     <td>{order.tracking_status}</td>
//                                     {/* <td>
//                                         <button className="action-button" onClick={() => console.log('Take Action', order.id)}>Take Action</button>
//                                         <button className="details-button" onClick={() => console.log('Show Details', order.id)}>Show Details</button>
//                                     </td> */}
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default NDRreports;


import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, query, where, limit, startAfter } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const NDRreports = () => {
    const [ndrOrders, setNdrOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [noMoreOrders, setNoMoreOrders] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');
    const ordersPerPage = 10;

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }

    // These are the NDR status values we want to display
    const statusValues = [
        'UNDELIVERED',
        'Delivery Attempt Failed',
        'Delivery Failed',
        'Delivery Attempted',
        'Undelivered - Customer Unavailable',
        'Undelivered - Wrong Address',
        'Undelivered - Customer Refused',
        'Undelivered - Damaged Package',
        'NDR',
        'Delivery Exception'
    ];

    const fetchNDROrders = async (isInitialLoad = true) => {
        try {
            if (isInitialLoad) {
                setLoading(true);
                setNdrOrders([]);
                setError(null);
            } else {
                setLoadingMore(true);
            }

            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            
            // Create a query with "in" operator for the tracking_status field
            let q = query(
                ordersCollection,
                where('tracking_status', 'in', statusValues),
                limit(ordersPerPage)
            );

            // If we're loading more, use the lastVisible document as the starting point
            if (!isInitialLoad && lastVisible) {
                q = query(
                    ordersCollection,
                    where('tracking_status', 'in', statusValues),
                    startAfter(lastVisible),
                    limit(ordersPerPage)
                );
            }

            const ordersSnapshot = await getDocs(q);
            
            // If no documents returned and not initial load, we've reached the end
            if (ordersSnapshot.empty && !isInitialLoad) {
                setNoMoreOrders(true);
                setLoadingMore(false);
                return;
            }

            const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            // Update lastVisible for pagination
            const lastDoc = ordersSnapshot.docs[ordersSnapshot.docs.length - 1];
            setLastVisible(lastDoc);

            // Append or set orders based on initial load or loading more
            setNdrOrders(prevOrders => (
                isInitialLoad ? ordersList : [...prevOrders, ...ordersList]
            ));

        } catch (error) {
            console.error("Error fetching NDR orders:", error);
            setError(`Error fetching orders: ${error.message}`);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    const loadMoreOrders = () => {
        if (!loadingMore && !noMoreOrders) {
            fetchNDROrders(false);
        }
    };

    // For search functionality across all orders
    const [allOrders, setAllOrders] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    const fetchAllOrdersForSearch = async () => {
        if (searchTerm.length >= 3) {
            setSearchLoading(true);
            try {
                const ordersCollection = collection(db, 'users', user.uid, 'orders');
                const q = query(
                    ordersCollection,
                    where('tracking_status', 'in', statusValues)
                );
                
                const ordersSnapshot = await getDocs(q);
                const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setAllOrders(ordersList);
                
                // Filter based on search term
                const filtered = ordersList.filter(order => 
                    order.id.toLowerCase().includes(searchTerm.toLowerCase()) || 
                    (order.Storeorder_id && order.Storeorder_id.toLowerCase().includes(searchTerm.toLowerCase()))
                );
                setFilteredOrders(filtered);
            } catch (error) {
                console.error("Error fetching all orders for search:", error);
                setError(`Error searching orders: ${error.message}`);
            } finally {
                setSearchLoading(false);
            }
        } else if (searchTerm.length === 0) {
            // Reset to paginated view
            setFilteredOrders(ndrOrders);
        }
    };

    useEffect(() => {
        fetchNDROrders(true);
    }, [shopId]);

    useEffect(() => {
        if (searchTerm.length >= 3) {
            fetchAllOrdersForSearch();
        } else if (searchTerm.length === 0) {
            setFilteredOrders(ndrOrders);
        } else {
            // For 1-2 characters, just filter the current paginated results
            const filtered = ndrOrders.filter(order => 
                order.id.toLowerCase().includes(searchTerm.toLowerCase()) || 
                (order.Storeorder_id && order.Storeorder_id.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredOrders(filtered);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm.length === 0) {
            setFilteredOrders(ndrOrders);
        }
    }, [ndrOrders]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    if (loading) {
        return <div className="loading-container">Loading NDR orders...</div>;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>NDR Orders</h1>
                    <div className="search-container">
                        <input 
                            type="text" 
                            placeholder="Search by Order ID or Store Order ID" 
                            value={searchTerm} 
                            onChange={handleSearch} 
                            className="search-bar"
                        />
                        {searchLoading && <span className="search-loader">Searching...</span>}
                    </div>
                    
                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}
                    
                    {filteredOrders.length > 0 ? (
                        <table className="orders-table">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Unitee Order ID</th>
                                    <th>SKU</th>
                                    <th>Quantity</th>
                                    <th>Current Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOrders.map(order => (
                                    <tr key={order.id}>
                                        <td>{order.Storeorder_id || 'NA'}</td>
                                        <td>{order.id || 'NA'}</td>
                                        <td>
                                            {order.order_items && order.order_items.map ? (
                                                order.order_items.map((item, index) => (
                                                    <p key={index}>{item.sku}</p>
                                                ))
                                            ) : (
                                                <p>No items found</p>
                                            )}
                                        </td>
                                        <td>
                                            {order.order_items && order.order_items.map ? (
                                                order.order_items.map((item, index) => (
                                                    <p key={index}>{item.units}</p>
                                                ))
                                            ) : (
                                                <p>No items found</p>
                                            )}
                                        </td>
                                        <td>{order.tracking_status || 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="no-orders">
                            {error ? null : "No NDR orders found"}
                        </div>
                    )}
                    
                    {searchTerm.length === 0 && !noMoreOrders && ndrOrders.length > 0 && (
                        <div className="load-more-container">
                            <button 
                                onClick={loadMoreOrders} 
                                disabled={loadingMore}
                                className="load-more-button"
                            >
                                {loadingMore ? 'Loading...' : 'Load More'}
                            </button>
                        </div>
                    )}
                    
                    {searchTerm.length === 0 && noMoreOrders && ndrOrders.length > 0 && (
                        <div className="no-more-orders">No more orders to load</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NDRreports;