// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const RTOOrders = () => {
//     const [rtoOrders, setRtoOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [trackingData, setTrackingData] = useState({}); // Store tracking data (status + URL)
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop');

//     const rtoStatuses = [
//         "RTO Initiated",
//         "RTO Delivered",
//         "RTO Acknowledged",
//         "RTO Rejected",
//         "RTO IN INTRANSIT",
//         "RTO_NDR"
//     ];

//     const user = auth.currentUser;
//     if (!user) {
//         throw new Error("User not logged in");
//     }

//     // Fetch RTO orders from Firestore
//     const fetchRtoOrders = async () => {
//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const ordersSnapshot = await getDocs(ordersCollection);
//             const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             setRtoOrders(ordersList);

//             // Fetch tracking data for each order
//             const newTrackingData = {};
//             for (const order of ordersList) {
//                 const trackingInfo = await fetchTrackingData(order.id);
//                 newTrackingData[order.id] = trackingInfo;
//             }
//             setTrackingData(newTrackingData);
//         } catch (error) {
//             console.error("Error fetching RTO orders:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Fetch tracking status and URL from backend
//     const fetchTrackingData = async (orderId) => {
//         try {
//             const response = await axios.get(`http://localhost:8080/api/track-order?order_id=${orderId}`);
//             if (response.data && response.data.current_status) {
//                 return {
//                     tracking_url: response.data.tracking_url,
//                     current_status: response.data.current_status
//                 };
//             }
//         } catch (error) {
//             console.error("Error fetching tracking data for order", orderId, error);
//         }
//         return { tracking_url: null, current_status: "Status not available" };
//     };

//     useEffect(() => {
//         fetchRtoOrders();
//     }, [shopId]);

//     if (loading) {
//         return <div>Loading RTO orders...</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>RTO Orders</h1>
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th>Unitee Order ID</th>
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 <th>Current Status</th>
//                                 <th>Tracking URL</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {rtoOrders
//                                 .filter(order => rtoStatuses.includes(trackingData[order.id]?.current_status))
//                                 .map(order => (
//                                     <tr key={order.id}>
//                                         <td>{order.Storeorder_id || 'NA'}</td>
//                                         <td>{order.id || 'NA'}</td>
//                                         <td>
//                                             {order.order_items.map((item, index) => (
//                                                 <p key={index}>{item.sku}</p>
//                                             ))}
//                                         </td>
//                                         <td>
//                                             {order.order_items.map((item, index) => (
//                                                 <p key={index}>{item.units}</p>
//                                             ))}
//                                         </td>
//                                         <td>{trackingData[order.id]?.current_status || 'Fetching...'}</td>
//                                         <td>
//                                             {trackingData[order.id]?.tracking_url ? (
//                                                 <a href={trackingData[order.id].tracking_url} target="_blank" rel="noopener noreferrer">
//                                                     Track Order
//                                                 </a>
//                                             ) : (
//                                                 <span>Not Available</span>
//                                             )}
//                                         </td>
//                                     </tr>
//                                 ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default RTOOrders;




import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, getDoc, updateDoc, setDoc, Timestamp } from 'firebase/firestore';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const RTOOrders = () => {
    const [rtoOrders, setRtoOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [trackingData, setTrackingData] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');

    const rtoStatuses = [
        "RTO Initiated",
        "RTO Delivered",
        "RTO Acknowledged",
        "RTO Rejected",
        "RTO IN INTRANSIT",
        "RTO_NDR",
        "RTO_OFD"
    ];

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }


    

    // Fetch RTO orders from Firestore
    const fetchRtoOrders = async () => {
        try {
            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            const ordersSnapshot = await getDocs(ordersCollection);
            const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setRtoOrders(ordersList);

            const newTrackingData = {};
            for (const order of ordersList) {
                const trackingInfo = await fetchTrackingData(order.id);
                newTrackingData[order.id] = trackingInfo;

                // ✅ Deduct ₹50 only if "RTO Initiated" and not already charged
                // if (trackingInfo.current_status === "RTO Initiated") {
                //     await handleRtoInitiated(order.id);
                // }

                if (trackingInfo.current_status === "RTO Delivered") {
                    await handleRtoDelivered(order.id, order.order_items);
                }
            }
            setTrackingData(newTrackingData);
        } catch (error) {
            console.error("Error fetching RTO orders:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch tracking status and URL from backend
    const fetchTrackingData = async (orderId) => {
        try {
            const response = await axios.get(`https://ufb-1.onrender.com/api/track-order?order_id=${orderId}`);
            if (response.data && response.data.current_status) {
                return {
                    tracking_url: response.data.tracking_url,
                    current_status: response.data.current_status
                };
            }
        } catch (error) {
            console.error("Error fetching tracking data for order", orderId, error);
        }
        return { tracking_url: null, current_status: "Status not available" };
    };

    // ✅ Handle "RTO Initiated" by deducting ₹50 (only once per order)
    // const handleRtoInitiated = async (orderId) => {
    //     const orderRef = doc(db, 'users', user.uid, 'orders', orderId);
    //     const orderSnap = await getDoc(orderRef);

    //     if (!orderSnap.exists()) {
    //         console.warn(`Order ${orderId} does not exist.`);
    //         return;
    //     }

    //     const orderData = orderSnap.data();

    //     // ✅ If already marked, skip deduction
    //     if (orderData.RTO_Initiated) {
    //         console.log(`RTO charge already applied for order ${orderId}, skipping deduction.`);
    //         return;
    //     }

    //     // ✅ Mark order as "RTO Initiated" (before deduction to prevent race conditions)
    //     await updateDoc(orderRef, { RTO_Initiated: true });

    //     console.log(`Marked order ${orderId} as RTO Initiated.`);

    //     // ✅ Deduct ₹50 from the user's wallet
    //     await deductWalletBalance(orderId);
    // };

    // ✅ Deduct ₹50 from wallet and record transaction
    const deductWalletBalance = async (orderId) => {
        try {
            const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
            const walletSnap = await getDoc(walletRef);

            if (!walletSnap.exists()) {
                console.warn("Wallet does not exist for this user.");
                return;
            }

            const walletData = walletSnap.data();
            const currentBalance = walletData.balance;

            if (currentBalance < 50) {
                console.warn("Insufficient balance to deduct ₹50.");
                return;
            }

            const updatedBalance = currentBalance - 50;

            // ✅ Update wallet balance
            await updateDoc(walletRef, { balance: updatedBalance });

            // ✅ Store transaction details (Prevent duplicate charges)
            const transactionsRef = collection(db, 'users', user.uid, 'wallet', 'walletDetails','transactions');
            const transactionDocRef = doc(transactionsRef, orderId); // Keep Order ID as key

            const transactionData = {
                type: "RTO Initiated",
                orderId: orderId,
                amount: 50,
                balanceBefore: currentBalance,
                balanceAfter: updatedBalance,
                timestamp: Timestamp.now(),
            };

            await setDoc(transactionDocRef, transactionData); // ✅ Use setDoc to prevent duplicate transactions for the same order

            console.log(`₹50 deducted for order ${orderId}. New balance: ₹${updatedBalance}`);
        } catch (error) {
            console.error("Error updating wallet balance:", error);
        }
    };

    // ✅ Handle "RTO Delivered" - using user.uid as brandId
    const handleRtoDelivered = async (orderId, orderItems) => {
        const orderRef = doc(db, 'users', user.uid, 'orders', orderId);
        const orderSnap = await getDoc(orderRef);
    
        if (!orderSnap.exists()) {
            console.warn(`Order ${orderId} does not exist.`);
            return;
        }
    
        const orderData = orderSnap.data();
    
        // ✅ Skip if already marked as RTO_Delivered
        if (orderData.RTO_Delivered) {
            console.log(`Order ${orderId} already processed for RTO Delivered.`);
            return;
        }
        
        // ✅ Skip if already added to RTO inventory
        if (orderData.RTO_inventory) {
            console.log(`Order ${orderId} already added to RTO inventory.`);
            return;
        }
    
        // ✅ Use user.uid as brandId
        const brandId = user.uid;
    
        // ✅ Prepare inventory update with cleaned item names
        const itemsDetails = orderItems.map(item => {
            // Clean item name by removing size suffix (like -L, -XL, etc.)
            const itemName = cleanItemName(item.name);
            
            return {
                sku: item.sku,
                units: item.units,
                itemName: itemName
            };
        });
    
        const inventoryUpdated = await updateRtoInventory(itemsDetails, brandId);
        
        if (inventoryUpdated) {
            // ✅ Mark as delivered and inventory added only if inventory update succeeds
            await updateDoc(orderRef, { 
                RTO_Delivered: true,
                RTO_inventory: true 
            });
            console.log(`Marked order ${orderId} as RTO Delivered and added to inventory.`);
        } else {
            console.error(`Failed to update inventory for order ${orderId}, not marking as RTO Delivered.`);
        }
    };
    
    // Helper function to clean item name (remove size suffix)
    const cleanItemName = (name) => {
        // Remove size suffixes like -L, -XL, -XXL etc.
        const parts = name.split('-');
        if (parts.length < 3) return name;
        
        // Keep only the first two parts of the SKU (removing the size part)
        return parts.slice(0, 2).join('-');
    };


    
    const updateRtoInventory = async (items, brandId) => {
        try {
            for (const item of items) {
                const skuParts = item.sku.split('-');
                if (skuParts.length < 2) {
                    console.error('Invalid SKU format:', item.sku);
                    continue;
                }
    
                const skuPrefix = skuParts.slice(0, 2).join('-');
                const size = skuParts[2];
                const skuRef = doc(db, 'RTO_inventory', skuPrefix);
    
                const docSnap = await getDoc(skuRef);
    
                if (!docSnap.exists()) {
                    await setDoc(skuRef, {
                        [size]: item.units,
                        brandId: brandId,
                        itemName: item.itemName // ✅ Save cleaned item name
                    });
                    console.log(`Created new RTO inventory for ${skuPrefix} - ${size}: ${item.units} units.`);
                } else {
                    const currentData = docSnap.data();
                    const currentSizeQuantity = currentData[size] || 0;
                    
                    await updateDoc(skuRef, {
                        [size]: currentSizeQuantity + item.units,
                        brandId: brandId,
                        itemName: item.itemName // ✅ Save cleaned item name
                    });
                    console.log(`Updated RTO inventory for ${skuPrefix} - ${size}: ${item.units} units.`);
                }
            }
            return true; // ✅ Return success
        } catch (error) {
            console.error(`Error updating RTO inventory:`, error);
            return false; // ❌ Return failure
        }
    };
    
    useEffect(() => {
        fetchRtoOrders();
    }, [shopId]);

    useEffect(() => {
        const filtered = rtoOrders.filter(order => 
            order.id.includes(searchTerm) || 
            (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
        );
        setFilteredOrders(filtered);
    }, [searchTerm, rtoOrders]);

    if (loading) {
        return <div>Loading RTO orders...</div>;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>RTO Orders</h1>
                    <input 
                        type="text" 
                        placeholder="Search by Order ID or Store Order ID" 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                        className="search-bar"
                    />
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Unitee Order ID</th>
                                <th>SKU</th>
                                <th>Quantity</th>
                                <th>Current Status</th>
                                <th>Tracking URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrders
                                .filter(order => rtoStatuses.includes(trackingData[order.id]?.current_status))
                                .map(order => (
                                    <tr key={order.id}>
                                        <td>{order.Storeorder_id || 'NA'}</td>
                                        <td>{order.id || 'NA'}</td>
                                        <td>
                                            {order.order_items.map((item, index) => (
                                                <p key={index}>{item.sku}</p>
                                            ))}
                                        </td>
                                        <td>
                                            {order.order_items.map((item, index) => (
                                                <p key={index}>{item.units}</p>
                                            ))}
                                        </td>
                                        <td>{trackingData[order.id]?.current_status || 'Fetching...'}</td>
                                        <td>
                                            {trackingData[order.id]?.tracking_url ? (
                                                <a href={trackingData[order.id].tracking_url} target="_blank" rel="noopener noreferrer">
                                                    Track Order
                                                </a>
                                            ) : (
                                                <span>Not Available</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default RTOOrders;


// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase';
// import { collection, getDocs, doc, getDoc, updateDoc, setDoc, Timestamp } from 'firebase/firestore';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const RTOOrders = () => {
//     const [rtoOrders, setRtoOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [trackingData, setTrackingData] = useState({});
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop');

//     const rtoStatuses = [
//         "RTO Initiated",
//         "RTO Delivered",
//         "RTO Acknowledged",
//         "RTO Rejected",
//         "RTO IN INTRANSIT",
//         "RTO_NDR",
//         "RTO_OFD"
//     ];

//     const user = auth.currentUser;
//     if (!user) {
//         throw new Error("User not logged in");
//     }

//     // Fetch RTO orders from Firestore
//     const fetchRtoOrders = async () => {
//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const ordersSnapshot = await getDocs(ordersCollection);
//             const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             setRtoOrders(ordersList);

//             const newTrackingData = {};
//             for (const order of ordersList) {
//                 const trackingInfo = await fetchTrackingData(order.id);
//                 newTrackingData[order.id] = trackingInfo;

//                 // ✅ Deduct ₹50 only if "RTO Initiated" and not already charged
//                 if (trackingInfo.current_status === "RTO Initiated") {
//                     await handleRtoInitiated(order.id);
//                 }

//                 if (trackingInfo.current_status === "RTO Delivered") {
//                     await handleRtoDelivered(order.id, order.order_items, order.brandId);
//                 }
//             }
//             setTrackingData(newTrackingData);
//         } catch (error) {
//             console.error("Error fetching RTO orders:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Fetch tracking status and URL from backend
//     const fetchTrackingData = async (orderId) => {
//         try {
//             const response = await axios.get(`http://localhost:8080/api/track-order?order_id=${orderId}`);
//             if (response.data && response.data.current_status) {
//                 return {
//                     tracking_url: response.data.tracking_url,
//                     current_status: response.data.current_status
//                 };
//             }
//         } catch (error) {
//             console.error("Error fetching tracking data for order", orderId, error);
//         }
//         return { tracking_url: null, current_status: "Status not available" };
//     };

//     // ✅ Handle "RTO Initiated" by deducting ₹50 (only once per order)
//     const handleRtoInitiated = async (orderId) => {
//         const orderRef = doc(db, 'users', user.uid, 'orders', orderId);
//         const orderSnap = await getDoc(orderRef);

//         if (!orderSnap.exists()) {
//             console.warn(`Order ${orderId} does not exist.`);
//             return;
//         }

//         const orderData = orderSnap.data();

//         // ✅ If already marked, skip deduction
//         if (orderData.RTO_Initiated) {
//             console.log(`RTO charge already applied for order ${orderId}, skipping deduction.`);
//             return;
//         }

//         // ✅ Mark order as "RTO Initiated" (before deduction to prevent race conditions)
//         await updateDoc(orderRef, { RTO_Initiated: true });

//         console.log(`Marked order ${orderId} as RTO Initiated.`);

//         // ✅ Deduct ₹50 from the user's wallet
//         await deductWalletBalance(orderId);
//     };

//     // ✅ Deduct ₹50 from wallet and record transaction
//     const deductWalletBalance = async (orderId) => {
//         try {
//             const walletRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
//             const walletSnap = await getDoc(walletRef);

//             if (!walletSnap.exists()) {
//                 console.warn("Wallet does not exist for this user.");
//                 return;
//             }

//             const walletData = walletSnap.data();
//             const currentBalance = walletData.balance;

//             if (currentBalance < 50) {
//                 console.warn("Insufficient balance to deduct ₹50.");
//                 return;
//             }

//             const updatedBalance = currentBalance - 50;

//             // ✅ Update wallet balance
//             await updateDoc(walletRef, { balance: updatedBalance });

//             // ✅ Store transaction details (Prevent duplicate charges)
//             const transactionsRef = collection(db, 'users', user.uid, 'wallet', 'walletDetails','transactions');
//             const transactionDocRef = doc(transactionsRef, orderId); // Keep Order ID as key

//             const transactionData = {
//                 type: "RTO Initiated",
//                 orderId: orderId,
//                 amount: 50,
//                 balanceBefore: currentBalance,
//                 balanceAfter: updatedBalance,
//                 timestamp: Timestamp.now(),
//             };

//             await setDoc(transactionDocRef, transactionData); // ✅ Use setDoc to prevent duplicate transactions for the same order

//             console.log(`₹50 deducted for order ${orderId}. New balance: ₹${updatedBalance}`);
//         } catch (error) {
//             console.error("Error updating wallet balance:", error);
//         }
//     };


//     const handleRtoDelivered = async (orderId, orderItems, brandId) => {
//         const orderRef = doc(db, 'users', user.uid, 'orders', orderId);
//         const orderSnap = await getDoc(orderRef);
    
//         if (!orderSnap.exists()) {
//             console.warn(`Order ${orderId} does not exist.`);
//             return;
//         }
    
//         const orderData = orderSnap.data();
    
//         // ✅ Skip if already marked
//         if (orderData.RTO_Delivered) {
//             console.log(`Order ${orderId} already processed for RTO Delivered.`);
//             return;
//         }
    
//         // ✅ Prepare inventory update
//         const itemsDetails = orderItems.map(item => ({
//             sku: item.sku,
//             units: item.units,
//         }));
    
//         const inventoryUpdated = await updateRtoInventory(itemsDetails, brandId);
        
//         if (inventoryUpdated) {
//             // ✅ Mark as delivered only if inventory update succeeds
//             await updateDoc(orderRef, { RTO_Delivered: true });
//             console.log(`Marked order ${orderId} as RTO Delivered.`);
//         } else {
//             console.error(`Failed to update inventory for order ${orderId}, not marking as RTO Delivered.`);
//         }
//     };
    
//     const updateRtoInventory = async (items, brandId) => {
//         try {
//             for (const item of items) {
//                 const skuParts = item.sku.split('-');
//                 if (skuParts.length < 2) {
//                     console.error('Invalid SKU format:', item.sku);
//                     continue;
//                 }
    
//                 const skuPrefix = skuParts.slice(0, 2).join('-');
//                 const size = skuParts[2];
//                 const skuRef = doc(db, 'RTO_inventory', skuPrefix);
    
//                 const docSnap = await getDoc(skuRef);
    
//                 if (!docSnap.exists()) {
//                     await setDoc(skuRef, {
//                         [size]: item.units,
//                         brandId,
//                     });
//                     console.log(`Created new RTO inventory for ${skuPrefix} - ${size}: ${item.units} units.`);
//                 } else {
//                     await updateDoc(skuRef, {
//                         [size]: item.units,
//                     });
//                     console.log(`Updated RTO inventory for ${skuPrefix} - ${size}: ${item.units} units.`);
//                 }
//             }
//             return true; // ✅ Return success
//         } catch (error) {
//             console.error(`Error updating RTO inventory:`, error);
//             return false; // ❌ Return failure
//         }
//     };
    

//     useEffect(() => {
//         fetchRtoOrders();
//     }, [shopId]);

//     if (loading) {
//         return <div>Loading RTO orders...</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>RTO Orders</h1>
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th>Unitee Order ID</th>
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 <th>Current Status</th>
//                                 <th>Tracking URL</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {rtoOrders
//                                 .filter(order => rtoStatuses.includes(trackingData[order.id]?.current_status))
//                                 .map(order => (
//                                     <tr key={order.id}>
//                                         <td>{order.Storeorder_id || 'NA'}</td>
//                                         <td>{order.id || 'NA'}</td>
//                                         <td>
//                                             {order.order_items.map((item, index) => (
//                                                 <p key={index}>{item.sku}</p>
//                                             ))}
//                                         </td>
//                                         <td>
//                                             {order.order_items.map((item, index) => (
//                                                 <p key={index}>{item.units}</p>
//                                             ))}
//                                         </td>
//                                         <td>{trackingData[order.id]?.current_status || 'Fetching...'}</td>
//                                         <td>
//                                             {trackingData[order.id]?.tracking_url ? (
//                                                 <a href={trackingData[order.id].tracking_url} target="_blank" rel="noopener noreferrer">
//                                                     Track Order
//                                                 </a>
//                                             ) : (
//                                                 <span>Not Available</span>
//                                             )}
//                                         </td>
//                                     </tr>
//                                 ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default RTOOrders;
