import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';


const Delivered = () => {
    const [deliveredOrders, setDeliveredOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
        const [loadingMore, setLoadingMore] = useState(false);
        const [noMoreOrders, setNoMoreOrders] = useState(false);
        const [error, setError] = useState(null);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');
    const [searchLoading, setSearchLoading] = useState(false);

    

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }

    const fetchDeliveredOrders = async () => {
        try {
            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            // Create a query for orders with status "Delivered"
            const deliveredQuery = query(ordersCollection, where("tracking_status", "==", "DELIVERED"));
            const ordersSnapshot = await getDocs(deliveredQuery);
            
            const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setDeliveredOrders(ordersList);
        } catch (error) {
            console.error("Error fetching delivered orders:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDeliveredOrders();
    }, [shopId]);

    useEffect(() => {
        const filtered = deliveredOrders.filter(order => 
            order.id.includes(searchTerm) || 
            (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
        );
        setFilteredOrders(filtered);
    }, [searchTerm, deliveredOrders]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };


    const loadMoreOrders = () => {
        if (!loadingMore && !noMoreOrders) {
            fetchDeliveredOrders(false);
        }
    };

    if (loading) {
        return <div>Loading Delivered Orders</div>;
    }

    return (
            <div className="orders-page">
                <Header />
                <div className="orders-container">
                    <Sidebar />
                    <div className="main-content">
                        <h1>Delivered Orders</h1>
                        <div className="search-container">
                            <input 
                                type="text" 
                                placeholder="Search by Order ID or Store Order ID" 
                                value={searchTerm} 
                                onChange={handleSearch} 
                                className="search-bar"
                            />
                            {searchLoading && <span className="search-loader">Searching...</span>}
                        </div>
                        
                        {error && (
                            <div className="error-message">
                                {error}
                            </div>
                        )}
                        
                        {filteredOrders.length > 0 ? (
                            <table className="orders-table">
                                <thead>
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Unitee Order ID</th>
                                        <th>SKU</th>
                                        <th>Quantity</th>
                                        <th>Current Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredOrders.map(order => (
                                        <tr key={order.id}>
                                            <td>{order.Storeorder_id || 'NA'}</td>
                                            <td>{order.id || 'NA'}</td>
                                            <td>
                                                {order.order_items && order.order_items.map ? (
                                                    order.order_items.map((item, index) => (
                                                        <p key={index}>{item.sku}</p>
                                                    ))
                                                ) : (
                                                    <p>No items found</p>
                                                )}
                                            </td>
                                            <td>
                                                {order.order_items && order.order_items.map ? (
                                                    order.order_items.map((item, index) => (
                                                        <p key={index}>{item.units}</p>
                                                    ))
                                                ) : (
                                                    <p>No items found</p>
                                                )}
                                            </td>
                                            <td>{order.tracking_status || 'NA'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="no-orders">
                                {error ? null : "No orders found with the specified tracking statuses"}
                            </div>
                        )}
                        
                        {searchTerm.length === 0 && !noMoreOrders && deliveredOrders.length > 0 && (
                            <div className="load-more-container">
                                <button 
                                    onClick={loadMoreOrders} 
                                    disabled={loadingMore}
                                    className="load-more-button"
                                >
                                    {loadingMore ? 'Loading...' : 'Load More'}
                                </button>
                            </div>
                        )}
                        
                        {searchTerm.length === 0 && noMoreOrders && deliveredOrders.length > 0 && (
                            <div className="no-more-orders">No more orders to load</div>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    
    export default Delivered;